import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { radioWidth } from 'pages/pet/quote-summary/styles';

export const StyledRichText = styled(RichText)`
  ${fonts.paragraphLarge}
  margin-bottom: ${spacing(2)};
`;

export const PercentageExcessHeading = styled.h3`
  ${fonts.headingSmall};

  margin: ${spacing(3)} 0 ${spacing(2)};
`;

export const PercentageExcessRadioInput = styled(RadioInput)`
  margin-bottom: ${spacing(2)};
  ${radioWidth}
  ${RadioItem} {
    padding: ${spacing(1.5)} ${spacing(1.5)};
    margin-left: ${spacing(1)};
    flex-grow: 1;
    ${mediaQuery.tabletLandscape`
      flex-grow: 1;
      &:first-of-type {
        margin-left: ${spacing(0)};
      }
    `};
  }
`;
