import FlexibleWidthDivider from '@rsa-digital/evo-shared-components/components/Divider/FlexibleWidthDivider';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
`;

export const SectionIntro = styled(LargeRichTextWithModal)`
  margin-top: ${spacing(-1)};
`;

export const ExcessSectionWrapper = styled.div`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;

export const FlexibleWidthDividerWithMargins = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(6)};
  `}
`;
