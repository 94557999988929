import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import PriceBanner from '@rsa-digital/evo-shared-components/components/PriceBanner';
import { PriceWrapper } from '@rsa-digital/evo-shared-components/components/PriceBanner/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import FormFooter from 'components/FormFooter';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import RichTextLarge from 'components/RichTextLarge';
import { InfoPanel } from 'components/StatusPanel';
import SectionHeading from 'forms/SectionHeading';

export const LargeRichTextWithModalAndMargin = styled(RichTextLarge)`
  margin-bottom: ${spacing(2)};

  h2 {
    margin-top: 0;

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(4)};
    `}
  }
`;

export const InfoPanelWithMargin = styled(InfoPanel)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(5)};
  `}
`;

export const SectionHeadingWithMargin = styled(SectionHeading)`
  h2 {
    margin-bottom: ${spacing(2)};
  }

  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    h2 {
      margin-bottom: ${spacing(3)};
    }
    margin-bottom: 0;
  `}
`;

export const ExcessSectionHeading = styled(SectionHeadingWithMargin)`
  margin-top: 0;
  margin-bottom: 0;

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const PriceBannerSpace = styled.div`
  height: ${spacing(12)};

  ${mediaQuery.tabletPortrait`
    height: ${spacing(8)};
  `}
`;

export const OfferPanel = styled(PromotionalBox)`
  background-color: ${colors.notificationInfoLight};

  p.large {
    ${fonts.paragraph};
  }

  && {
    border: none;
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(6)};

    ${mediaQuery.tabletPortrait`
      padding-right: ${spacing(4)};
    `}

    ${mediaQuery.tabletLandscape`
      p.large {
        ${fonts.paragraphLarge}; 
      }

      margin-top: ${spacing(8)};
    `}
  }
`;

/*  On firefox the FormFooter overlays the PageFooter when downloading the quote/saving as a pdf this rule
is to prevent that overlay so that the FormFooter sits above the PageFooter and doesn't obscure any text.
*/
export const StyledFormFooter = styled(FormFooter)`
  @-moz-document url-prefix() {
    @media print {
      margin-bottom: ${spacing(25)};
    }
  }
`;
export const radioWidth = css`
  width: calc(100% + 8px);
  margin-left: ${spacing(-1)};
  ${mediaQuery.tabletLandscape`
    width: 100%;
    margin-left: 0;
  `}
`;

export const VetFeeLimitRadioInput = styled(RadioInput)`
  margin-bottom: ${spacing(1)};
  ${radioWidth}
  ${RadioItem} {
    padding: ${spacing(1.5)} ${spacing(1)};
    margin-left: ${spacing(1)};
    ${mediaQuery.tabletLandscape`
      flex-grow: 1;
      &:first-of-type {
        margin-left: ${spacing(0)};
      }
    `};
  }
`;

export const SummaryOfNeedsSectionHeading = styled(SectionHeading)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(3)};

  h2 {
    margin: 0;
  }

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const QuestionFieldWithMarginTop = styled(QuestionField)`
  margin-top: ${spacing(2)};
  margin-bottom: 0;
`;

export const VetFeeLimitInfoPanel = styled(InfoPanel)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)}
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)}
  `}
`;

export const StyledPriceBanner = styled(PriceBanner)`
  ${PriceWrapper} {
    text-align: left;
    padding-left: ${spacing(2)};
    p {
      text-align: left;
    }
  }
  ${mediaQuery.tabletLandscape`
         ${PriceWrapper} {
           text-align: left;
           padding-left: ${spacing(0)};
           p {
             text-align: center;
           }
         }
  `}
`;
