import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import React from 'react';
import {
  ChartTextContainer,
  DonutChartContainer,
  DonutRing,
  DonutSegment,
  FullSizedSvg,
} from './styles';
import { DonutChartGridItem } from '../styles';

type DonutChartProps = {
  coveredClaimsPercentage: number;
  chartText: string;
};

const DonutChart: React.FC<DonutChartProps> = ({
  coveredClaimsPercentage,
  chartText,
}) => {
  // Circumference needs to be 100 for the cover percentage to work nicely
  const radius = 100 / (Math.PI * 2);

  return (
    <DonutChartGridItem desktop={3} tabletPortrait={3} tabletLandscape={3}>
      <DonutChartContainer>
        {/* 34 is the biggest width/height value before the viewbox starts clipping the chart */}
        <FullSizedSvg viewBox="0 0 34 34">
          <DonutRing r={radius} cx="50%" cy="50%" />
          <DonutSegment
            r={radius}
            cx="50%"
            cy="50%"
            radius={radius}
            percentageCovered={coveredClaimsPercentage}
          />
        </FullSizedSvg>
        <ChartTextContainer>
          <RichText html={chartText} />
        </ChartTextContainer>
      </DonutChartContainer>
    </DonutChartGridItem>
  );
};

export default DonutChart;
