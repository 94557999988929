import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ExampleBox from 'components/ExampleBox';
import { VoluntaryExcessPercentage } from 'helpers/businessConstants';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackPercentageExcessSelection } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { excessPlaceholders } from 'helpers/placeholders/excessPlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getVetFeeLimitFromProduct } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import {
  PercentageExcessHeading,
  PercentageExcessRadioInput,
  StyledRichText,
} from './styles';

type CsExcessSection = {
  csPetQuoteSummaryChooseExcessV2: {
    choose_percentage_excess_question: CsQuestion;
    examples: {
      heading: string;
      average_claim: {
        heading: string;
        average_claim_heading: string;
        rich_text_body: string;
        average_claim_payment: string;
      };
      vet_fee_limit_claim: {
        heading: string;
        vet_fee_limit_claim_heading: string;
        rich_text_body: string;
        vet_fee_limit_claim_payment: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      choose_percentage_excess_question {
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
      examples {
        heading
        average_claim {
          heading
          average_claim_heading
          rich_text_body
          average_claim_payment
        }
        vet_fee_limit_claim {
          heading
          vet_fee_limit_claim_heading
          rich_text_body
          vet_fee_limit_claim_payment
        }
      }
    }
  }
`;

type ExcessProps = {
  quoteUpdaters: QuoteUpdaters;
  pet: QuoteResponsePetInfo;
  petIndex: number;
};

const PercentageExcessSection: React.FC<ExcessProps> = ({
  quoteUpdaters,
  pet,
  petIndex,
}) => {
  const { choose_percentage_excess_question, examples } = useStaticQuery<CsExcessSection>(
    query
  ).csPetQuoteSummaryChooseExcessV2;
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const percentageExcessQuestion = processQuestion(choose_percentage_excess_question);

  const quote = useCurrentQuote();
  const defaultErrorHandling = useDefaultErrorHandling();

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [voluntaryExcessPercentage, setVoluntaryExcessPercentage] = useState<
    VoluntaryExcessPercentage
  >();

  useEffect(() => {
    setVoluntaryExcessPercentage(pet?.voluntaryExcessPercentage);
  }, [pet]);

  const baseExcessPlaceholderData = {
    fixedExcess: pet ? parseInt(pet.voluntaryExcessAmount, 10) : 0,
    percentageExcess: pet ? parseInt(pet.voluntaryExcessPercentage, 10) : 0,
  };

  const richTextLowClaimPlaceholderReplacer = replacePlaceholdersRichText(
    excessPlaceholders,
    { ...baseExcessPlaceholderData, claimValue: 800 }
  );
  const richTextHighClaimPlaceholderReplacer = replacePlaceholdersRichText(
    excessPlaceholders,
    {
      ...baseExcessPlaceholderData,
      claimValue: getVetFeeLimitFromProduct(quote.quoteOptions.productType) as number,
    }
  );
  const plainTextLowClaimPlaceholderReplacer = replacePlaceholdersPlainText(
    excessPlaceholders,
    {
      ...baseExcessPlaceholderData,
      claimValue: 800,
    }
  );
  if (!pet) {
    return null;
  }

  const substituteName = replacePlaceholdersQuestion((csString: string) =>
    petNameReplacer(pet.petName, csString)
  );

  return (
    <>
      <PercentageExcessHeading>
        {substituteName(percentageExcessQuestion).questionText}
      </PercentageExcessHeading>
      {percentageExcessQuestion.explanatoryText && (
        <StyledRichText
          html={petNameReplacer(pet.petName, percentageExcessQuestion.explanatoryText)}
        />
      )}
      <PercentageExcessRadioInput
        id={`petPercentageExcess[${petIndex}]`}
        value={voluntaryExcessPercentage}
        orientationOverride={Orientation.horizontal}
        buttonsPerRow={{ mobile: 3, tabletPortrait: 3 }}
        options={[
          { name: '0%', value: VoluntaryExcessPercentage.EXCESS_0_PERCENT },
          { name: '10%', value: VoluntaryExcessPercentage.EXCESS_10_PERCENT },
          { name: '20%', value: VoluntaryExcessPercentage.EXCESS_20_PERCENT },
        ]}
        onChange={async (e) => {
          if (!quoteUpdaters) {
            console.error('No quote loaded - could not update quote');
            return;
          }
          setVoluntaryExcessPercentage(e.target.value as VoluntaryExcessPercentage);
          trackPercentageExcessSelection(
            petIndex,
            e.target.value as VoluntaryExcessPercentage
          );
          try {
            await quoteUpdaters.updatePetExcessPercentage(
              petIndex,
              e.target.value as VoluntaryExcessPercentage
            );
          } catch (error) {
            defaultErrorHandling(error);
          }
        }}
      />
      <Grid alignLeft>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          <ExampleBox
            id={`examplebox[${petIndex}]`}
            pageTitle={PageTitle.QuoteSummary}
            subheading={plainTextLowClaimPlaceholderReplacer(examples.heading)}
            averageClaimTextHeading={richTextLowClaimPlaceholderReplacer(
              examples.average_claim.average_claim_heading
            )}
            averageClaimPayment={richTextLowClaimPlaceholderReplacer(
              examples.average_claim.average_claim_payment
            )}
            averageClaimText={richTextLowClaimPlaceholderReplacer(
              examples.average_claim.rich_text_body
            )}
            largeClaimTextHeading={richTextHighClaimPlaceholderReplacer(
              examples.vet_fee_limit_claim.vet_fee_limit_claim_heading
            )}
            largeClaimText={richTextHighClaimPlaceholderReplacer(
              examples.vet_fee_limit_claim.rich_text_body
            )}
            largeClaimPayment={richTextHighClaimPlaceholderReplacer(
              examples.vet_fee_limit_claim.vet_fee_limit_claim_payment
            )}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default PercentageExcessSection;
