import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import {
  BottomLabel,
  CornerLabel,
  StyledPanel,
} from '@rsa-digital/evo-shared-components/components/Panel/LabelledPanel/styles';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import ExampleBox from 'components/ExampleBox';
import {
  ContentWrapper,
  ExampleWrapper,
  SubheadingRichText,
} from 'components/ExampleBox/styles';
import QuestionField from 'components/QuestionField';

export const GridItemFlex = styled(GridItem)`
  display: grid;
  grid-template-columns: min-content auto;
  flex-wrap: nowrap;
  ${mediaQuery.tabletPortrait`
    margin-left: 0;
`};
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
  display: block;
  grid-column: 1/2;
`;

export const ExcessContent = styled.div`
  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(2)};
    grid-column: 2;
`};
  grid-column: 1 / span 2;
`;

export const ExampleBoxWrapper = styled.div`
  margin: ${spacing(2)} ${spacing(1)} ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(3)};
  `};
`;

export const ExampleBoxStyled = styled(ExampleBox)`
  border: none;
  padding: ${spacing(4)} ${spacing(3)};

  && {
    ${ContentWrapper} {
      ${mediaQuery.tabletPortrait`
        gap: ${spacing(6)};
      `};
    }

    ${ExampleWrapper} {
      ${mediaQuery.tabletPortrait`
        width: 100%;
      `};
    }

    ${SubheadingRichText} {
      ${fonts.headingSmall};
    }
  }
`;

export const StyledSimplePanel = styled(SimplePanel)`
  && {
    padding: ${spacing(3)} ${spacing(2)} ${spacing(2)};
  }
  ${mediaQuery.tabletPortrait`
    && {
    padding: ${spacing(3)} ${spacing(3)} ${spacing(3)};
  }
  `};
`;

export const SectionIntroHeading = styled.h3`
  ${fonts.headingSmall};
  margin-top: 0;
  grid-column: 2;
`;

export const FlexibleWidthDividerWithMargins = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(4)};
`;

export const QuestionFieldWithMargin = styled(QuestionField)`
  ${(props) =>
    props.className?.includes('validationFailed')
      ? ''
      : `border: 1px solid ${colors.core01} !important;`}
  margin-bottom: 0;
  && {
    ${CornerLabel} {
      background: ${colors.neutral05};
    }
    ${ContentSpacing} {
      margin-top: 0;
    }
    ${BottomLabel} {
      background: ${colors.neutral05};
      margin-bottom: 0;

      div {
        margin: -${spacing(2)} ${spacing(6)} ${spacing(3)};
      }
    }
    && {
      ${StyledPanel} {
        background: ${colors.neutral05};
        padding: ${spacing(2)} ${spacing(3)} ${spacing(4)};
        position: relative;
        z-index: 0;

        ${mediaQuery.tabletPortrait`
          padding-bottom: ${spacing(3)};
        `}
      }
    }
  }

  ${StyledRadioInput} {
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};

    ${mediaQuery.tabletPortrait`
        flex-direction: row;
        gap: ${spacing(4)};
    `}

    ${RadioItem} {
      margin-left: 0;
    }
  }
`;

export const QuestionFieldWrapper = styled.div`
  margin: ${spacing(5)} ${spacing(1)} ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
  `};
`;
