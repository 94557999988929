import { ProductType } from 'state/formData/quoteOptions';

export const getVetFeeLimitName = (product: ProductType): string => {
  switch (product) {
    case ProductType.Vet_Fee_Limit_2000: {
      return '£2,000';
    }
    case ProductType.Vet_Fee_Limit_3000: {
      return '£3,000';
    }
    case ProductType.Vet_Fee_Limit_5000: {
      return '£5,000';
    }
    case ProductType.Vet_Fee_Limit_7500: {
      return '£7,500';
    }
    case ProductType.Vet_Fee_Limit_9000: {
      return '£9,000';
    }
    case ProductType.Vet_Fee_Limit_12000: {
      return '£12,000';
    }
    default: {
      return '';
    }
  }
};
