import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import PolicyLink from 'components/PolicyDocuments/PolicyLink';

export const ProductFeaturesTableContainer = styled.div`
  margin: 0 ${spacing(3)} ${spacing(3.75)};

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} ${spacing(5)} ${spacing(3.25)};
  `}
`;

export const ProductFeaturesList = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0;
`;

export const ProductFeaturesTableHeading = styled.h3`
  ${fonts.headingSmall};
  text-align: center;
`;

export const ParagraphLargeSpan = styled.span`
  ${fonts.paragraphLarge};
  & {
    font-weight: ${heavyFontWeight};
  }

  margin-right: ${spacing(1)};
`;

export const InlineFlexPolicyLink = styled(PolicyLink)`
  display: inline-flex;
`;

export const DividerWithMarginBottom = styled(Divider)`
  margin-bottom: ${spacing(3)};
`;
