import { CsPlaceholders } from './replaceCsPlaceholders';

export type CoverPercentagePlaceholderData = {
  coveredClaimsPercentage: number;
  coverLimit: string;
  petType: string;
};

export const coverPercentagePlaceholders: CsPlaceholders<CoverPercentagePlaceholderData> = {
  coverLimit: {
    getSubstitutionText: (placeholders) => placeholders.coverLimit,
    isPii: false,
  },
  coveredClaimsPercentage: {
    getSubstitutionText: (placeholders) => `${placeholders.coveredClaimsPercentage}%`,
    isPii: false,
  },
  notCoveredClaimsPercentage: {
    getSubstitutionText: (placeholders) =>
      `${100 - placeholders.coveredClaimsPercentage}%`,
    isPii: false,
  },
  petType: {
    getSubstitutionText: (placeholders) => placeholders.petType,
    isPii: false,
  },
};
