import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
`;

export const LargeRichTextWithModalAndMargin = styled(LargeRichTextWithModal)`
  margin-bottom: ${spacing(2)};
`;

export const ExcessStatementWrapper = styled.div`
  margin-top: ${spacing(4)};
`;

export const GridWithMargin = styled(Grid)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;
