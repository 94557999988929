import { VoluntaryExcessAmount, VoluntaryExcessPercentage } from './businessConstants';

export const getExcessFromExcessAmount = (
  excessAmount: VoluntaryExcessAmount
): number => {
  switch (excessAmount) {
    case VoluntaryExcessAmount.Excess_60:
      return 60;
    case VoluntaryExcessAmount.Excess_80:
      return 80;
    case VoluntaryExcessAmount.Excess_100:
      return 100;
    case VoluntaryExcessAmount.Excess_140:
      return 140;
    case VoluntaryExcessAmount.Excess_250:
      return 250;
    case VoluntaryExcessAmount.Excess_500:
      return 500;
    default:
      return 0;
  }
};

export const getPercentageFromVoluntaryExcessPercentage = (
  voluntaryExcessPercentage: VoluntaryExcessPercentage
): number => {
  switch (voluntaryExcessPercentage) {
    default:
    case VoluntaryExcessPercentage.EXCESS_0_PERCENT:
      return 0;
    case VoluntaryExcessPercentage.EXCESS_10_PERCENT:
      return 10;
    case VoluntaryExcessPercentage.EXCESS_20_PERCENT:
      return 20;
  }
};
