import { graphql, useStaticQuery } from 'gatsby';
import { ProductType } from 'state/formData/quoteOptions';
import { warningWithDetail } from './errorReporting';
import { PetType } from './getPetTypesBeingInsured';
import { getProductFromProductType, Product } from './productHelpers';

type CsPercentageOfClaimsCovered = {
  csPetQuoteSummaryCoverPanel: {
    percentage_of_claims_covered: {
      cover_percentages_cats_and_dogs: {
        products: { product_selector: Product[] };
        percentage: number;
      }[];
      cover_percentages_dogs_only: {
        products: { product_selector: Product[] };
        percentage: number;
      }[];
      cover_percentages_cats_only: {
        products: { product_selector: Product[] };
        percentage: number;
      }[];
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryCoverPanel {
      percentage_of_claims_covered {
        cover_percentages_cats_and_dogs {
          products {
            product_selector
          }
          percentage
        }
        cover_percentages_dogs_only {
          products {
            product_selector
          }
          percentage
        }
        cover_percentages_cats_only {
          products {
            product_selector
          }
          percentage
        }
      }
    }
  }
`;

const usePercentageOfClaimsCovered = (
  feeLimit: ProductType,
  petsInQuote: PetType
): number => {
  const {
    cover_percentages_cats_and_dogs: cover_percentages_cats_dogs,
    cover_percentages_dogs_only: cover_percentages_dogs,
    cover_percentages_cats_only: cover_percentages_cats,
  } = useStaticQuery<CsPercentageOfClaimsCovered>(
    query
  ).csPetQuoteSummaryCoverPanel.percentage_of_claims_covered;

  const selectedProducts = getProductFromProductType(feeLimit);

  const catsAndDogsPercentage = cover_percentages_cats_dogs.find((cover_percentages) =>
    cover_percentages.products.product_selector.some(
      (products) => products === selectedProducts
    )
  )?.percentage;

  const dogsPercentage = cover_percentages_dogs.find((cover_percentages) => {
    return cover_percentages.products.product_selector.some(
      (products) => products === selectedProducts
    );
  })?.percentage;

  const catsPercentage = cover_percentages_cats.find((cover_percentages) => {
    return cover_percentages.products.product_selector.some(
      (products) => products === selectedProducts
    );
  })?.percentage;

  if (catsAndDogsPercentage && dogsPercentage && catsPercentage) {
    switch (petsInQuote) {
      case PetType.CatsAndDogs:
        return catsAndDogsPercentage;
      case PetType.DogsOnly:
        return dogsPercentage;
      case PetType.CatsOnly:
        return catsPercentage;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered',
          `for product type ${selectedProducts}`
        );
        return 0;
    }
  }

  warningWithDetail(
    'Could not find percentage of claims covered',
    `for product type ${selectedProducts}`
  );
  return 0;
};

export default usePercentageOfClaimsCovered;
