import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const Heading = styled.h3`
  ${fonts.headingSmall}

  margin: ${spacing(4)} 0 ${spacing(2)};
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  && {
    p,
    li {
      ${fonts.paragraphLarge}
    }
    span.bullet {
      vertical-align: initial;
    }
  }
`;

export const DividerWithMargin = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(4)};
`;
