import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import CustomModal from 'components/CustomModal';
import { Link } from 'components/PolicyDocuments';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackDownloadClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsIcon, CsModal } from 'types/contentStack';
import ProductFeatureComponent from './ProductFeature';
import { InlineDiv } from './ProductFeature/styles';
import {
  DividerWithMarginBottom,
  InlineFlexPolicyLink,
  ParagraphLargeSpan,
  ProductFeaturesList,
  ProductFeaturesTableContainer,
  ProductFeaturesTableHeading,
} from './styles';

type PolicyBookletContent = {
  csPetPolicyDocumentsV2: {
    bullet_icon: [CsIcon];
    policy_booklet: Link;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      bullet_icon {
        icon_code
      }
      policy_booklet {
        url
        text
        screenreader_text
        open_in_new_tab
      }
    }
  }
`;

type ProductFeaturesTableProps = {
  features: ProductFeature[];
  forMoreBenefitsText: string;
  thisCoverIncludesText: string;
};

export type ProductFeature = {
  modal: [] | [CsModal];
  feature_text: string;
};

const ProductFeaturesTable: React.FC<ProductFeaturesTableProps> = ({
  features,
  forMoreBenefitsText,
  thisCoverIncludesText,
}) => {
  const policyBookletContent = useStaticQuery<PolicyBookletContent>(query)
    .csPetPolicyDocumentsV2;

  const icon = unwrapSingleton(policyBookletContent.bullet_icon)?.icon_code;

  const [modalId, setModalId] = useState<string | null>(null);
  const pageTitle = PageTitle.QuoteSummary;

  const quote = useCurrentQuote();
  const richTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  return (
    <ProductFeaturesTableContainer>
      <CustomModal
        modalId={modalId}
        onClose={() => setModalId(null)}
        richTextPlaceholderReplacer={richTextPlaceholderReplacer}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
        pageTitle={pageTitle}
      />
      <ProductFeaturesTableHeading>{thisCoverIncludesText}</ProductFeaturesTableHeading>
      <ProductFeaturesList>
        {features.map((feature) => (
          <li key={feature.feature_text}>
            <ProductFeatureComponent
              modal={feature.modal}
              featureText={feature.feature_text}
              setModalId={setModalId}
            />
          </li>
        ))}
      </ProductFeaturesList>
      <DividerWithMarginBottom aria-hidden />
      <span>
        <ParagraphLargeSpan>{forMoreBenefitsText}</ParagraphLargeSpan>
        <InlineDiv>
          <InlineFlexPolicyLink
            icon={icon}
            url={policyBookletContent.policy_booklet.url}
            text={policyBookletContent.policy_booklet.text}
            screenreaderText={
              policyBookletContent.policy_booklet.screenreader_text ?? undefined
            }
            openInNewTab={policyBookletContent.policy_booklet.open_in_new_tab}
            onClick={() =>
              trackDownloadClick(
                'Policy booklet document',
                policyBookletContent.policy_booklet.url
              )
            }
          />
        </InlineDiv>
      </span>
    </ProductFeaturesTableContainer>
  );
};

export default ProductFeaturesTable;
