import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackExcessSelection } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { lowVetFeesLimitSelected } from 'pages/pet/quote-summary/validation';
import {
  ExcessSectionWrapper,
  FixedExcessHeading,
  FixedExcessRadioInput,
  RichTextWithModalAndMargins,
} from './styles';

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcessV2: {
    fixed_excess_section: {
      heading: string;
      explanatory_text: string;
      third_party_liability_disclaimer: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      fixed_excess_section {
        heading
        explanatory_text
        third_party_liability_disclaimer
      }
    }
  }
`;

type ChooseExcessProps = {
  quoteUpdaters: QuoteUpdaters;
  pet: QuoteResponsePetInfo;
  petIndex: number;
};

const ChooseExcessSection: React.FC<ChooseExcessProps> = ({
  quoteUpdaters,
  pet,
  petIndex,
}) => {
  const { fixed_excess_section } = useStaticQuery<CsChooseExcessSection>(
    query
  ).csPetQuoteSummaryChooseExcessV2;

  const defaultErrorHandling = useDefaultErrorHandling();
  const quote = useCurrentQuote();

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [voluntaryExcessAmount, setVoluntaryExcessAmount] = useState<
    VoluntaryExcessAmount | undefined
  >();

  const lowVetFeeLimitOptions = [
    { name: '£60', value: VoluntaryExcessAmount.Excess_60 },
    { name: '£80', value: VoluntaryExcessAmount.Excess_80 },
    { name: '£100', value: VoluntaryExcessAmount.Excess_100 },
    { name: '£140', value: VoluntaryExcessAmount.Excess_140 },
    { name: '£250', value: VoluntaryExcessAmount.Excess_250 },
  ];

  const highVetFeeLimitOptions = [
    ...lowVetFeeLimitOptions,
    { name: '£500', value: VoluntaryExcessAmount.Excess_500 },
  ];

  useEffect(() => {
    setVoluntaryExcessAmount(pet.voluntaryExcessAmount);
  }, [pet]);

  return (
    <ExcessSectionWrapper>
      <FixedExcessHeading>
        {petNameReplacer(pet.petName, fixed_excess_section.heading)}
      </FixedExcessHeading>
      <RichTextWithModalAndMargins
        html={fixed_excess_section.explanatory_text}
        pageTitle={PageTitle.QuoteSummary}
      />
      <FixedExcessRadioInput
        id={`petExcess[${petIndex}]`}
        value={voluntaryExcessAmount}
        orientationOverride={Orientation.horizontal}
        buttonsPerRow={{ mobile: 3, tabletPortrait: 3 }}
        options={
          lowVetFeesLimitSelected(quote.quoteOptions)
            ? lowVetFeeLimitOptions
            : highVetFeeLimitOptions
        }
        onChange={async (e) => {
          if (!quoteUpdaters) {
            console.error('No quote loaded - could not update quote');
            return;
          }

          setVoluntaryExcessAmount(e.target.value as VoluntaryExcessAmount);
          trackExcessSelection(petIndex, e.target.value as VoluntaryExcessAmount);
          try {
            await quoteUpdaters.updatePetExcess(
              petIndex,
              e.target.value as VoluntaryExcessAmount
            );
          } catch (error) {
            defaultErrorHandling(error);
          }
        }}
      />
      {pet.petType === petType_DOG && (
        <RichTextWithModalAndMargins
          html={petNameReplacer(
            pet.petName,
            fixed_excess_section.third_party_liability_disclaimer
          )}
          pageTitle={PageTitle.QuoteSummary}
        />
      )}
    </ExcessSectionWrapper>
  );
};

export default ChooseExcessSection;
