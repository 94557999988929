import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import { ErrorPanel, SuccessPanel } from 'components/StatusPanel';

export const FieldWrapper = styled.div`
  margin-bottom: ${spacing(2)};
`;

const panelMargins = css`
  && {
    padding-left: ${spacing(2)};
  }

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(-1)};
  `}
`;

export const SuccessPanelWithMargins = styled(SuccessPanel)`
  ${panelMargins}
`;

export const ErrorPanelWithMargins = styled(ErrorPanel)`
  ${panelMargins}
`;
