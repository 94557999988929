import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { radioWidth } from 'pages/pet/quote-summary/styles';

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
`;

export const SectionIntro = styled(LargeRichTextWithModal)`
  margin-bottom: ${spacing(4)};
  margin-top: ${spacing(-1)};
`;

export const ExcessSectionWrapper = styled.div`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;

export const FixedExcessRadioInput = styled(RadioInput)`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
  ${radioWidth}
  ${RadioItem} {
    padding: ${spacing(1.5)} ${spacing(1.5)};
    flex-grow: 0;
    margin-left: ${spacing(1)};
    ${mediaQuery.tabletLandscape`
      flex-grow: 1;
      &:first-of-type {
        margin-left: ${spacing(0)};
      }
    `};
  }

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
    margin-bottom: ${spacing(2)};
  `}
`;

export const FixedExcessHeading = styled.h3`
  ${fonts.headingSmall};

  margin: ${spacing(3)} 0 ${spacing(2)};
`;

export const RichTextWithModalAndMargins = styled(RichTextWithModal)`
  margin: ${spacing(2)} 0 ${spacing(1)};
  p {
    ${fonts.paragraphLarge};
  }
`;
