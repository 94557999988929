import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { petsPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { QuoteOptions } from 'state/formData/quoteOptions';
import PetExcessSection from './PetExcessSection';
import { ExcessSectionWrapper, SectionIntro } from './styles';

type ExcessProps = {
  quoteUpdaters: QuoteUpdaters;
  pageTitle: PageTitle;
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
    showValidation: FieldFunction<QuoteOptions, void>;
  };
};

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcessV2: {
    intro_text: string;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      intro_text
    }
  }
`;

const ExcessSection: React.FC<ExcessProps> = ({
  quoteUpdaters,
  pageTitle,
  formValidation,
}) => {
  const { intro_text } = useStaticQuery<CsChooseExcessSection>(
    query
  ).csPetQuoteSummaryChooseExcessV2;

  const quote = useCurrentQuote();

  const pets = quote.petInfos;

  const replacePetNames = replacePlaceholdersPlainText(
    petsPlaceholders,
    pets ?? [],
    true
  );

  if (!pets) {
    return null;
  }

  return (
    <ExcessSectionWrapper>
      <SectionIntro pageTitle={pageTitle} html={replacePetNames(intro_text)} />
      {pets.map((pet, index) => (
        <PetExcessSection
          // Index is the unique identifier for pet info
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          quoteUpdaters={quoteUpdaters}
          pageTitle={pageTitle}
          pet={pet}
          petIndex={index}
          formValidation={formValidation}
        />
      ))}
    </ExcessSectionWrapper>
  );
};

export default ExcessSection;
