import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
  align-items: center;
  page-break-inside: avoid;
  border-top: 1px solid ${colors.neutral03};
`;

export const IconWithMargins = styled(Icon)`
  margin-right: ${spacing(3)};
`;

export const StyledButtonLink = styled(ButtonLink)`
  && {
    ${fonts.paragraphLarge}
    color: ${colors.core01};
  }
  text-decoration: underline;
  text-align: left;
  display: inline;
`;

export const ParagraphSpanLight = styled.span`
  ${fonts.paragraphLarge};
  & {
    font-weight: ${lightFontWeight};
  }
  margin: 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing(2)};
`;

export const InlineDiv = styled.div`
  display: inline;
  padding: 0;
  margin: 0;
`;
