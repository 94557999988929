import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { TextInputProps } from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import TextInputWithApply from '@rsa-digital/evo-shared-components/components/Form/TextInputWithApply';
import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import componentProps from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { graphql, useStaticQuery } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import QuestionField from 'components/QuestionField';
import { Question } from 'types/forms';
import { ErrorPanelWithMargins, SuccessPanelWithMargins } from './styles';

type CsApplyRemoveButtonTexts = {
  csPetGlobalConfig: {
    apply_remove_button_texts: {
      apply_button_text: string;
      remove_button_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetGlobalConfig {
      apply_remove_button_texts {
        apply_button_text
        remove_button_text
      }
    }
  }
`;

type InputWithApplyAndPanelProps = {
  inputId: string;
  lookupStatus: LookupStatus;
  text: {
    successPanel: string;
    errorPanel?: string;
  };
  disableApply: boolean;
  onApply(): void;
  onRemove(): void;
  applySuccess: boolean;
  question: Question;
  errorText?: string;
  addAriaLabel: string;
  removeAriaLabel: string;
  className?: string;
  id?: string;
  questionGridItemProps?: GridItemProps;
  displaySuccessPanel?: boolean;
} & TextInputProps;

const InputWithApplyAndPanel: React.FC<PropsWithChildren<
  InputWithApplyAndPanelProps
>> = ({
  inputId,
  lookupStatus,
  text: { successPanel, errorPanel },
  disableApply,
  onApply,
  onRemove,
  applySuccess,
  question,
  errorText,
  addAriaLabel,
  removeAriaLabel,
  className,
  id,
  questionGridItemProps,
  displaySuccessPanel = lookupStatus === LookupStatus.Success,
  ...props
}) => {
  const { apply_button_text, remove_button_text } = useStaticQuery<
    CsApplyRemoveButtonTexts
  >(query).csPetGlobalConfig.apply_remove_button_texts;

  return (
    <div {...componentProps({ className, id })}>
      <QuestionField
        question={question}
        errorText={errorText}
        gridItemProps={questionGridItemProps}>
        <TextInputWithApply
          onApply={onApply}
          onRemove={onRemove}
          disableApply={disableApply}
          addText={apply_button_text}
          removeText={remove_button_text}
          applySuccess={applySuccess}
          {...props}
          id={inputId}
          addAriaLabel={addAriaLabel}
          removeAriaLabel={removeAriaLabel}
        />
      </QuestionField>
      {displaySuccessPanel && (
        <FieldGrid alignLeft>
          <SuccessPanelWithMargins data-cy={`${inputId}-successPanel`} aria-live="polite">
            <RichText html={successPanel} />
          </SuccessPanelWithMargins>
        </FieldGrid>
      )}
      {(lookupStatus === LookupStatus.NotFound ||
        lookupStatus === LookupStatus.ApiUnavailable) &&
        errorPanel && (
          <FieldGrid alignLeft>
            <ErrorPanelWithMargins
              data-cy={`${inputId}-errorPanel`}
              aria-live="assertive">
              <RichText html={errorPanel} />
            </ErrorPanelWithMargins>
          </FieldGrid>
        )}
    </div>
  );
};

export default InputWithApplyAndPanel;
