import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { getVetFeeLimitName } from 'helpers/vetFeeLimitHelper';
import {
  EmphasisedText,
  EmphasisedTextWithoutPadding,
  Mobile,
  MobilePriceBannerTextWrapper,
  PriceBannerTextWrapper,
  StyledDiv,
  StyledLinkText,
  StyledText,
  TabletLandscape,
  TabletPortrait,
  VerticalDivider,
} from './styles';

export type PriceBannerTextProps = {
  csPetQuoteSummaryV2: {
    price_banner: {
      vet_fee_limit_variant: string;
      fixed_excess_variant: string;
      chosen_excess_variant: string;
      compulsory_excess_variant: string;
      view_excess_link_text_variant: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryV2 {
      price_banner {
        vet_fee_limit_variant
        fixed_excess_variant
        chosen_excess_variant
        compulsory_excess_variant
        view_excess_link_text_variant
      }
    }
  }
`;

export const PriceBannerText: React.FC = () => {
  const { price_banner } = useStaticQuery<PriceBannerTextProps>(
    query
  ).csPetQuoteSummaryV2;
  const quote = useCurrentQuote();
  const vetFeeLimit = quote.quoteOptions.productType
    ? getVetFeeLimitName(quote.quoteOptions.productType)
    : '';

  const getCoverStartDate = (coverStartDate: string | undefined): Date =>
    coverStartDate ? new Date(coverStartDate) : new Date();

  const getVoluntaryExcess = (): string | undefined => {
    if (!quote.petInfos) {
      return undefined;
    }
    return formatInPoundsWithSign(parseInt(quote.petInfos[0].voluntaryExcessAmount, 10));
  };

  const getVoluntaryExcessPercentage = (): string | undefined => {
    if (!quote.petInfos) {
      return undefined;
    }
    return `${parseInt(quote.petInfos[0].voluntaryExcessPercentage, 10)}%`;
  };

  const getSecondaryExcessText = (): string => {
    return isPet9YearsOrOlderOnDate(
      quote.petInfos?.[0].dob,
      getCoverStartDate(quote.policyInfo?.coverStartDate)
    )
      ? price_banner.compulsory_excess_variant
      : price_banner.chosen_excess_variant;
  };

  const isMultiPet = (): boolean => {
    return (quote.petInfos?.length || 0) > 1;
  };

  return (
    <>
      <TabletLandscape>
        <PriceBannerTextWrapper>
          <EmphasisedTextWithoutPadding>{vetFeeLimit}</EmphasisedTextWithoutPadding>
          <StyledText>{price_banner.vet_fee_limit_variant}</StyledText>
          <VerticalDivider />
          {!isMultiPet() && (
            <>
              <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
              <StyledText>{price_banner.fixed_excess_variant}</StyledText>
              <EmphasisedTextWithoutPadding>+</EmphasisedTextWithoutPadding>
              <EmphasisedTextWithoutPadding>
                {getVoluntaryExcessPercentage()}
              </EmphasisedTextWithoutPadding>
              <StyledText>{getSecondaryExcessText()}</StyledText>
            </>
          )}
          {isMultiPet() && (
            <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
              {price_banner.view_excess_link_text_variant}
            </StyledLinkText>
          )}
        </PriceBannerTextWrapper>
      </TabletLandscape>
      <TabletPortrait>
        <MobilePriceBannerTextWrapper>
          <StyledDiv>
            <EmphasisedText>{vetFeeLimit}</EmphasisedText>
            <StyledText>{price_banner.vet_fee_limit_variant}</StyledText>
          </StyledDiv>
          <StyledDiv>
            {!isMultiPet() && (
              <>
                <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
                <StyledText>{price_banner.fixed_excess_variant}</StyledText>
                <EmphasisedTextWithoutPadding>+</EmphasisedTextWithoutPadding>
                <EmphasisedTextWithoutPadding>
                  {getVoluntaryExcessPercentage()}
                </EmphasisedTextWithoutPadding>
                <StyledText>{getSecondaryExcessText()}</StyledText>
              </>
            )}
            {isMultiPet() && (
              <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
                {price_banner.view_excess_link_text_variant}
              </StyledLinkText>
            )}
          </StyledDiv>
        </MobilePriceBannerTextWrapper>
      </TabletPortrait>
      <Mobile>
        <MobilePriceBannerTextWrapper>
          <StyledDiv>
            <EmphasisedText>{vetFeeLimit}</EmphasisedText>
            <StyledText>{price_banner.vet_fee_limit_variant}</StyledText>
          </StyledDiv>
          <StyledDiv>
            {!isMultiPet() && (
              <>
                <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
                <StyledText>{price_banner.fixed_excess_variant}</StyledText>
              </>
            )}
            {isMultiPet() && (
              <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
                {price_banner.view_excess_link_text_variant}
              </StyledLinkText>
            )}
          </StyledDiv>
          <StyledDiv>
            {!isMultiPet() && (
              <>
                <EmphasisedTextWithoutPadding>
                  {getVoluntaryExcessPercentage()}
                </EmphasisedTextWithoutPadding>
                <StyledText>{getSecondaryExcessText()}</StyledText>
              </>
            )}
          </StyledDiv>
        </MobilePriceBannerTextWrapper>
      </Mobile>
    </>
  );
};
