import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const StyledSpanHeadingLarge = styled.span`
  ${fonts.headingLarge}
  display: block;

  ${mediaQuery.tabletPortrait`
    
    margin: 0 ${spacing(1)} 0 0;
  `}
`;

export const StyledSpanLight = styled.span`
  ${fonts.paragraph};
  & {
    font-weight: ${lightFontWeight};
  }
  margin: ${spacing(1)} ${spacing(1)} ${spacing(1)} 0;
  display: block;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
`;

export const StyledButtonLink = styled(ButtonLink)`
  && {
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
      text-align: left;
    `}
  }
`;
