import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { PageTitle } from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { ProductType, QuoteOptions } from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import {
  FlexibleWidthDividerWithMargins,
  QuestionFieldWithoutMargin,
  StyledInfoPanel,
  StyledRichTextWithModal,
} from './styles';

type CsSummaryOfYourNeedsAdditionalQuestion = {
  csPetQuoteSummaryOfYourNeedsV2: {
    vet_fees_limit_info_2k: string;
    vet_fees_limit_info_3k: string;
    no_button_text: string;
    yes_button_text: string;
    confirm_vet_fees_limit_question: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      vet_fees_limit_info_2k
      vet_fees_limit_info_3k
      no_button_text
      yes_button_text
      confirm_vet_fees_limit_question {
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
    }
  }
`;

type SummaryOfYourNeedsAdditionalQuestionProps = {
  pageTitle: PageTitle;
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
  };
};

const AdditionalQuestion: React.FC<SummaryOfYourNeedsAdditionalQuestionProps> = ({
  pageTitle,
  formValidation,
}) => {
  const csContent = useStaticQuery<CsSummaryOfYourNeedsAdditionalQuestion>(query)
    .csPetQuoteSummaryOfYourNeedsV2;

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const substituteQuoteQuestionPlaceholders = replacePlaceholdersQuestion(
    plainTextPlaceholderReplacer
  );

  const confirmVetFeesQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(csContent.confirm_vet_fees_limit_question)
  );

  const { getError } = formValidation;

  const vetFeesLimit2000 =
    quote.quoteOptions.productType === ProductType.Vet_Fee_Limit_2000;

  const questionId = 'hasConfirmedVetFeesLimit';

  return (
    <StyledInfoPanel data-cy="vet-fee-info-panel">
      <span>
        <StyledRichTextWithModal
          html={
            vetFeesLimit2000
              ? plainTextPlaceholderReplacer(csContent.vet_fees_limit_info_2k)
              : plainTextPlaceholderReplacer(csContent.vet_fees_limit_info_3k)
          }
          pageTitle={pageTitle}
        />
        <FlexibleWidthDividerWithMargins />
        <QuestionFieldWithoutMargin
          question={confirmVetFeesQuestion}
          errorText={getError(questionId)}
          gridItemProps={{
            desktop: 8,
            tabletLandscape: 8,
            tabletPortrait: 6,
          }}>
          <BooleanRadioInput
            id={questionId}
            value={quote.quoteOptions.hasConfirmedVetFeesLimit}
            yesLabel={csContent.yes_button_text}
            noLabel={csContent.no_button_text}
            onChange={(newValue) => {
              updateQuoteOptions({
                hasConfirmedVetFeesLimit: newValue,
              });
              if (!newValue) {
                scrollAndFocusInput('cover-level-radio');
              }
            }}
          />
        </QuestionFieldWithoutMargin>
      </span>
    </StyledInfoPanel>
  );
};

export default AdditionalQuestion;
