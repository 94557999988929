import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  fonts,
  lightFontWeight,
} from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';

export const StyledInfoPanel = styled(InfoPanel)`
  h3 {
    ${fonts.headingXSmall};
    margin-top: 0;
  }

  p {
    font-weight: ${lightFontWeight};
  }

  && {
    padding: ${spacing(3)};
  }

  margin: ${spacing(4)} 0;

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(2)};
    margin-bottom: ${spacing(6)};
  `}
`;

export const QuestionFieldWithoutMargin = styled(QuestionField)`
  margin-bottom: 0;
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  margin-bottom: ${spacing(3)};
`;

export const FlexibleWidthDividerWithMargins = styled(FlexibleWidthDivider)`
  margin-bottom: ${spacing(3)};
`;
