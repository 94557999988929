import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import {
  LookupStatus,
  useLookup,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import promotionsClient from 'apiHelpers/promotionsClient';
import useQuoteUpdaters from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import {
  PageTitle,
  trackAPIError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  initialPromoCodeLookup,
  initialQuoteOptions,
  isPromoCodeBlank,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import { InputWithApplyAndPanelWithTopMargin } from './styles';

type PromoCodeQuestionProps = {
  id: string;
  inputId: string;
  getError: FieldFunction<QuoteOptions, string | undefined>;
  pageTitle: PageTitle;
};

type CsPetPromoCode = {
  csPetPromoCode: {
    promo_code: CsQuestion & {
      success_panel_text: string;
      error_messages: {
        api_unavailable: string;
        invalid_promo_code: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetPromoCode {
      promo_code {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        error_messages {
          invalid_promo_code
          api_unavailable
        }
        success_panel_text
      }
    }
  }
`;

const PromoCodeQuestion: React.FC<PromoCodeQuestionProps> = ({
  id,
  inputId,
  getError,
  pageTitle,
}) => {
  const {
    csPetPromoCode: {
      promo_code: { success_panel_text, error_messages, ...question },
    },
  } = useStaticQuery<CsPetPromoCode>(query);
  const { processQuestionWithOptionalPlaceholder } = useQuestionProcessor(pageTitle);
  const processedQuestion = processQuestionWithOptionalPlaceholder(question);

  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const quoteUpdaters = useQuoteUpdaters();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const [isPromoCodeAppliedToQuote, setIsPromoCodeAppliedToQuote] = useState<boolean>(
    !!quote.quoteOptions.promoCode
  );

  const applyPromoCodeToQuote = async (promoCode: string): Promise<void> => {
    await quoteUpdaters?.updatePromoCode(promoCode);
    setIsPromoCodeAppliedToQuote(true);
  };

  const {
    performLookup: performPromoCodeLookup,
    getLookupError: getPromoCodeLookupError,
  } = useLookup(
    quoteOptions.promoCode,
    (update) => updateQuoteOptions({ promoCode: update }),
    promotionsClient.promoCode,
    () => null,
    {
      notFound: error_messages.invalid_promo_code,
      unavailable: error_messages.api_unavailable,
    },
    trackAPIError,
    applyPromoCodeToQuote
  );

  const removePromoCodeAndTriggerRequote = async (): Promise<void> => {
    try {
      await quoteUpdaters?.updatePromoCode('');
      setIsPromoCodeAppliedToQuote(false);
      updateQuoteOptions({ promoCode: initialPromoCodeLookup });
    } catch (e) {
      trackAPIError(e);
    }
  };

  return (
    <>
      {quoteUpdaters?.isLoading && (
        <LoadingOverlay loadingMessage="Updating promo code" />
      )}
      <InputWithApplyAndPanelWithTopMargin
        id={id}
        inputId={inputId}
        addAriaLabel="Apply promotional code"
        removeAriaLabel="Remove promotional code"
        question={processedQuestion}
        errorText={getError('promoCode') ?? getPromoCodeLookupError()}
        lookupStatus={quoteOptions.promoCode.status}
        text={{
          successPanel: success_panel_text,
        }}
        value={quoteOptions.promoCode.lookupKey}
        onChange={(e) =>
          updateQuoteOptions({
            promoCode: {
              ...initialPromoCodeLookup,
              lookupKey: e.target.value.toUpperCase().replaceAll(' ', ''),
            },
          })
        }
        placeholder={processedQuestion.placeholder}
        onFocus={trackFormTextFieldFocus('promoCode')}
        maxLength={12}
        loading={quoteOptions.promoCode.status === LookupStatus.InProgress}
        onKeyDown={(e) => {
          if (
            e.key === 'Enter' &&
            !(
              isPromoCodeBlank(quoteOptions) ||
              quoteOptions.promoCode.status === LookupStatus.InProgress
            )
          ) {
            e.preventDefault();
            performPromoCodeLookup();
          }
        }}
        onApply={() => {
          performPromoCodeLookup();
          trackTextButtonClick(pageTitle, 'Apply promocode');
        }}
        onRemove={async () => {
          await removePromoCodeAndTriggerRequote();
          trackTextButtonClick(pageTitle, 'Remove promocode');
        }}
        disableApply={isPromoCodeBlank(quoteOptions)}
        applySuccess={quoteOptions.promoCode.status === LookupStatus.Success}
        questionGridItemProps={{
          desktop: 6,
          tabletLandscape: 6,
          tabletPortrait: 6,
        }}
        displaySuccessPanel={
          isPromoCodeAppliedToQuote &&
          quoteOptions.promoCode.status === LookupStatus.Success
        }
      />
    </>
  );
};

export default PromoCodeQuestion;
