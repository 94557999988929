import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackDownloadClick } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import PolicyLink from './PolicyLink';

export type Link = {
  url: string;
  text: string;
  screenreader_text: string | null;
  open_in_new_tab: boolean;
};

type PolicyDocumentsContent = {
  csPetPolicyDocumentsV2: {
    bullet_icon: [CsIcon];
    product_information_document: Link;
    essential_information: Link;
    policy_booklet: Link;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      bullet_icon {
        icon_code
      }
      product_information_document {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      essential_information {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      policy_booklet {
        url
        text
        screenreader_text
        open_in_new_tab
      }
    }
  }
`;

const PolicyDocuments: React.FC = () => {
  const pageContent = useStaticQuery<PolicyDocumentsContent>(query)
    .csPetPolicyDocumentsV2;

  const icon = unwrapSingleton(pageContent.bullet_icon)?.icon_code;

  return (
    <div>
      <PolicyLink
        icon={icon}
        url={pageContent.product_information_document.url}
        text={pageContent.product_information_document.text}
        screenreaderText={
          pageContent.product_information_document.screenreader_text ?? undefined
        }
        openInNewTab={pageContent.product_information_document.open_in_new_tab}
        onClick={() =>
          trackDownloadClick(
            'Product information document',
            pageContent.product_information_document.url
          )
        }
      />
      <PolicyLink
        icon={icon}
        url={pageContent.essential_information.url}
        text={pageContent.essential_information.text}
        screenreaderText={
          pageContent.essential_information.screenreader_text ?? undefined
        }
        openInNewTab={pageContent.essential_information.open_in_new_tab}
        onClick={() =>
          trackDownloadClick(
            'Essential information document',
            pageContent.essential_information.url
          )
        }
      />
      <PolicyLink
        icon={icon}
        url={pageContent.policy_booklet.url}
        text={pageContent.policy_booklet.text}
        screenreaderText={pageContent.policy_booklet.screenreader_text ?? undefined}
        openInNewTab={pageContent.policy_booklet.open_in_new_tab}
        onClick={() =>
          trackDownloadClick('Policy booklet document', pageContent.policy_booklet.url)
        }
      />
    </div>
  );
};

export default PolicyDocuments;
