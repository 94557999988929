import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import AdditionalQuestion from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion';
import { petWillBeAgeNineAtCoverStartDate } from 'helpers/ageHelpers';
import { PageTitle } from 'helpers/eventTracking';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { lowVetFeesLimitSelected } from 'pages/pet/quote-summary/validation';
import { QuoteOptions } from 'state/formData/quoteOptions';
import {
  ExcessStatementWrapper,
  GridWithMargin,
  LargeRichTextWithModal,
  LargeRichTextWithModalAndMargin,
} from './styles';

type CsSummaryOfNeedsSection = {
  csPetQuoteSummaryOfYourNeedsV2: {
    text_summary: {
      per_pet_excess_statement: string;
      pet_over_9_text: string;
      pet_under_9_text: string;
      text_before_statement: string;
      text_after_statement: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      text_summary {
        per_pet_excess_statement
        pet_over_9_text
        pet_under_9_text
        text_before_statement
        text_after_statement
      }
    }
  }
`;

type SummaryOfYourNeedsSectionProps = {
  pageTitle: PageTitle;
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
  };
};

const SummaryOfYourNeedsSection: React.FC<SummaryOfYourNeedsSectionProps> = ({
  pageTitle,
  formValidation,
}) => {
  const {
    per_pet_excess_statement,
    pet_over_9_text,
    pet_under_9_text,
    text_after_statement,
    text_before_statement,
  } = useStaticQuery<CsSummaryOfNeedsSection>(
    query
  ).csPetQuoteSummaryOfYourNeedsV2.text_summary;
  const quote = useCurrentQuote();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const { getError } = formValidation;

  return (
    <GridWithMargin>
      <GridItem desktop={8} tabletLandscape={8}>
        <LargeRichTextWithModal
          pageTitle={pageTitle}
          html={substituteQuotePlacholders(text_before_statement)}
        />
        <ExcessStatementWrapper>
          {quote.petInfos?.map((pet) => {
            const substituteExcessStatementPetPlaceholders = replacePlaceholdersRichText(
              petPlaceholders,
              pet
            );
            const coPaymentText =
              quote.policyInfo &&
              petWillBeAgeNineAtCoverStartDate(pet.dob, quote.policyInfo.coverStartDate)
                ? pet_over_9_text
                : pet_under_9_text;
            return (
              <div key={generateKeyForElement(pet)}>
                <LargeRichTextWithModal
                  pageTitle={pageTitle}
                  html={substituteExcessStatementPetPlaceholders(
                    per_pet_excess_statement
                  )}
                />
                {coPaymentText && (
                  <LargeRichTextWithModalAndMargin
                    pageTitle={pageTitle}
                    html={substituteExcessStatementPetPlaceholders(coPaymentText)}
                  />
                )}
              </div>
            );
          })}
        </ExcessStatementWrapper>
        <LargeRichTextWithModal
          pageTitle={pageTitle}
          html={substituteQuotePlacholders(text_after_statement)}
        />
        {lowVetFeesLimitSelected(quote.quoteOptions) && (
          <AdditionalQuestion
            formValidation={{ getError }}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
      </GridItem>
    </GridWithMargin>
  );
};

export default SummaryOfYourNeedsSection;
