import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PolicyDocuments from 'components/PolicyDocuments';
import { BottomDivider, StyledHeading } from './styles';

type PolicyDocumentsContent = {
  csPetPolicyDocumentsV2: {
    heading: string;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      heading
    }
  }
`;

const PolicyDocumentsSection: React.FC = () => {
  const { heading } = useStaticQuery<PolicyDocumentsContent>(
    query
  ).csPetPolicyDocumentsV2;
  const sectionId = 'policy-documents-section-id';
  return (
    <Grid as="section" alignLeft aria-labelledby={sectionId}>
      <GridItem>
        <StyledHeading id={sectionId}>{heading}</StyledHeading>
      </GridItem>
      <GridItem>
        <PolicyDocuments />
        <BottomDivider />
      </GridItem>
    </Grid>
  );
};

export default PolicyDocumentsSection;
