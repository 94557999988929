import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledHeading = styled.h3`
  ${fonts.headingSmall}

  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(3)};
  `}
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      & {
        ${fonts.paragraphLarge};
        font-weight: 300;
      }
    }
  }

  margin-bottom: ${spacing(1)};
`;

export const BottomDivider = styled(Divider)`
  margin-top: ${spacing(6)};
`;
