import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { ProductId } from 'helpers/businessConstants';
import { PageTitle } from 'helpers/eventTracking';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { StyledImage, StyledSimplePanel } from './styles';

type AggregatorSummaryContent = {
  csPetAggregatorsV2: {
    aggregator_summary: string;
  };
};

const query = graphql`
  query {
    csPetAggregatorsV2 {
      aggregator_summary
    }
  }
`;

type AggregatorSummaryProps = {
  aggregator: ProductId;
  pageTitle: PageTitle;
};

const AggregatorSummary: React.FC<AggregatorSummaryProps> = ({
  aggregator,
  pageTitle,
}) => {
  const content = useStaticQuery<AggregatorSummaryContent>(query);
  const branding = useAggregatorBrandDetails(aggregator);

  const replaceAggregatorPlaceholder = replacePlaceholdersRichText(
    {
      aggregator: {
        getSubstitutionText: (displayName) => displayName,
        isPii: false,
      },
    },
    branding?.displayName || ''
  );

  return (
    <Grid alignLeft>
      <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
        <StyledSimplePanel
          data-cy="aggregator-summary"
          borderShadows={{ displayOutset: true }}>
          {branding?.logo && <StyledImage image={branding.logo} />}
          <RichTextWithModal
            pageTitle={pageTitle}
            html={replaceAggregatorPlaceholder(
              content.csPetAggregatorsV2.aggregator_summary
            )}
          />
        </StyledSimplePanel>
      </GridItem>
    </Grid>
  );
};

export default AggregatorSummary;
