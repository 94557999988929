import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { getProductPrices, useCurrentQuote } from 'helpers/useCurrentQuote';
import { ProductType } from 'state/formData/quoteOptions';
import {
  StyledButtonLink,
  StyledSpanHeadingLarge,
  StyledSpanLight,
  Wrapper,
} from './styles';
import { BannerTextGridItem } from '../styles';

type BannerTextProps = {
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  selectedPrice: ProductType;
};

type BannerTextCsProps = {
  csPetSummaryOfCoverInstallmentInfoV2: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
    apr_text: string;
  };
};

export const query = graphql`
  query {
    csPetSummaryOfCoverInstallmentInfoV2 {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
      apr_text
    }
  }
`;

const BannerText: React.FC<BannerTextProps> = ({
  isAnnualPayment,
  paymentOnClick,
  selectedPrice,
}) => {
  const {
    csPetSummaryOfCoverInstallmentInfoV2: {
      annual_installment_text,
      monthly_installment_text,
      switch_to_annual_link_text,
      switch_to_monthly_link_text,
      apr_text,
    },
  } = useStaticQuery<BannerTextCsProps>(query);

  const quote = useCurrentQuote();
  const substitutePlaceholders = replacePlaceholdersPlainText(quotePlaceholders, quote);

  const annualPrice = formatInPoundsWithSign(
    getProductPrices(quote, selectedPrice)?.annualPrice.total
  );
  const monthlyPrice = formatInPoundsWithSign(
    getProductPrices(quote, selectedPrice)?.monthlyPrice.total
  );

  return (
    <BannerTextGridItem desktop={5} tabletLandscape={5} tabletPortrait={3}>
      <StyledSpanLight>
        {isAnnualPayment ? annual_installment_text : monthly_installment_text}
      </StyledSpanLight>
      <Wrapper>
        <StyledSpanHeadingLarge>
          {isAnnualPayment ? annualPrice : monthlyPrice}
        </StyledSpanHeadingLarge>
        {!isAnnualPayment && <StyledSpanLight>{apr_text}</StyledSpanLight>}
      </Wrapper>
      <StyledButtonLink
        onClick={paymentOnClick}
        color={colors.core01}
        data-cy="togglePaymentOption">
        {isAnnualPayment
          ? `${substitutePlaceholders(switch_to_monthly_link_text)}`
          : `${substitutePlaceholders(switch_to_annual_link_text)}`}
      </StyledButtonLink>
    </BannerTextGridItem>
  );
};

export default BannerText;
