import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { DividerWithMargin, Heading, StyledRichTextWithModal } from './styles';

type AggregatorAgreementsContent = {
  csPetAggregatorsV2: {
    confirmation: {
      heading: string;
      agreements: string;
    };
  };
};

const query = graphql`
  query {
    csPetAggregatorsV2 {
      confirmation {
        heading
        agreements
      }
    }
  }
`;

const AggregatorAgreements: React.FC<{ pageTitle: PageTitle }> = ({ pageTitle }) => {
  const content = useStaticQuery<AggregatorAgreementsContent>(query).csPetAggregatorsV2
    .confirmation;

  const headingId = 'aggregator-agreements-heading';

  return (
    <Grid alignLeft as="section" aria-labelledby={headingId}>
      <GridItem desktop={8} tabletLandscape={8}>
        <DividerWithMargin desktop={8} tabletLandscape={8} />
        <Heading id={headingId}>{content.heading}</Heading>
        <StyledRichTextWithModal html={content.agreements} pageTitle={pageTitle} />
      </GridItem>
    </Grid>
  );
};

export default AggregatorAgreements;
