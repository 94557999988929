import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import InputWithApplyAndPanel from 'components/InputWithApplyAndPanel';

export const InputWithApplyAndPanelWithTopMargin = styled(InputWithApplyAndPanel)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}
`;
